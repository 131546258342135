

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { CarColorConfiguration, ConfigureColor } from '@/modules/car/types';
import { CONFIGURE_COLOR } from '@/modules/car/store';
import { Chrome } from 'vue-color';

const CareStore = namespace('car');

@Component({ components: { ChromePicker: Chrome } })
export default class ConfigureColorDialog extends Vue {
  @Prop() value!: CarColorConfiguration | null;

  @CareStore.Action(CONFIGURE_COLOR) configure!: (car: ConfigureColor) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  color: any = {
    hex: '#194d33'
  };

  car: ConfigureColor = {
    car_id: '',
    color: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchConfig(config: CarColorConfiguration) {
    if (!config) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.car = {
      car_id: '',
      color: ''
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    if (!this.value) return;

    this.color = { hex: this.value.color || '#000000'};

    this.car = {
      car_id: this.value.id,
      color: this.value.color
    };
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.configure({ ...this.car, color: this.color.hex });
  }
}
