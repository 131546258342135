

































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_COLORS, FETCH_LIST, GET_COLORS, GET_LIST } from '../../store';
import { Car, CarColorConfiguration } from '../../types';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import CreateCarDialog from '../components/CreateCarDialog.vue';
import AppTable from '@/components/AppTable.vue';
import CorrectCarDialog from '@/modules/car/admin/components/CorrectCarDialog.vue';
import ConfigureColorDialog from '@/modules/car/admin/components/ConfigureColorDialog.vue';

const CarStore = namespace('car');

@Component({
  components: {
    ConfigureColorDialog,
    CorrectCarDialog,
    CreateCarDialog,
    ListSearchDialog,
    AppTable,
    ErrorAlert
  }
})
export default class Overview extends Vue {
  @CarStore.Getter(GET_LIST) list!: Car[];
  @CarStore.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse<Car[]>>;

  @CarStore.Getter(GET_COLORS) colors!: CarColorConfiguration[];
  @CarStore.Action(FETCH_COLORS) fetchColors!: () => Promise<ApiResponse<CarColorConfiguration[]>>;

  search: string = '';
  error: any = null;
  loading: boolean = false;
  createDialog: boolean = false;
  correctCar: Car | null = null;
  configureColor: CarColorConfiguration | null = null;

  pagination: Pagination = {
    sortBy: ['model'],
    itemsPerPage: -1
  };

  get items() {
    return this.list;
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('car.model'), value: 'model' },
      { align: 'left', text: this.$i18n.t('car.registrationNumber'), value: 'registrationNumber' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false, width: '140px' }
    ];
  }

  getColorConfiguration(car: Car): CarColorConfiguration {
    const config = (this.colors || []).find(config => config.id === car.id);

    if (config) {
      return config;
    }

    return {
      id: car.id,
      color: '',
      name: car.name
    }
  }

  async created() {
    this.loading = true;
    this.error = null;

    await this.fetchColors();

    const { error } = await this.fetch();

    this.loading = false;

    if (error) {
      this.error = error;
    }
  }
}
