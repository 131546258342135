


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '../../model';
import { Car, WriteCar } from '@/modules/car/types';
import { CORRECT_CAR } from '@/modules/car/store';

const CareStore = namespace('car');

@Component({})
export default class CorrectCarDialog extends Vue {
  @Prop() value!: Car | null;

  @CareStore.Action(CORRECT_CAR) correctCar!: (car: WriteCar) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  car: WriteCar = {
    car_id: '',
    model: '',
    registration_number: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchTermSet(scopeOfFunctions: Car) {
    if (!scopeOfFunctions) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.car = {
      car_id: '',
      model: '',
      registration_number: ''
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    if (!this.value) return;

    this.car = MapViewToWrite(this.value);
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.correctCar(this.car);
  }
}
