


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import uuid from 'uuid/v4';
import { REGISTER_NEW_CAR } from '../../store';
import { WriteCar } from '../../types';
import { ApiResponse } from '@/components/types';

const CarStore = namespace('car');

@Component({})
export default class CreateCarDialog extends Vue {
  @Prop({ default: false }) value!: boolean;

  @CarStore.Action(REGISTER_NEW_CAR) registerNewCar!: (data: WriteCar) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  model: string = '';
  registrationNumber: string = '';

  get dialog() {
    return this.value;
  }

  set dialog(value) {
    this.$emit('input', value);
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (!value) {
      setTimeout(() => {
        this.clear();
      }, 200);

      return;
    }

    this.init();
  }

  clear() {
    this.model = '';
    this.registrationNumber = '';

    this.error = null;
    this.loading = false;
    this.dialog = false;
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    this.model = '';
    this.registrationNumber = '';
  }

  onError(error: any) {
    this.loading = false;
    this.error = error;
  }

  async save() {
    this.error = null;
    this.loading = true;

    return this.registerNewCar({
      car_id: uuid(),
      model: this.model,
      registration_number: this.registrationNumber
    });
  }
}
